import { React, useState } from 'react'
import "./footer.css"

import { previousStep, nextStep, playAll, playStep, repeatToggle, adjustSpeed, tick, pauseStep } from '../modelview/ModelView'



const Footer = () => {

// slider code START
let [value, setValue] = useState(0);
const MAX = 10;

const sliderChange = () => {
  // return {
  //   backgroundSize: `${(value * 100) / MAX}% 100%`,
  // };
};

const sliderValue = () => {
  tick(value);
}

const tickBackward = () => {
  if (value == 0){
    setValue(value)
  }
  else {
    setValue(value - 1)
    tick(value);
  }
}

const tickForward = () => {
  if (value == MAX){
    setValue(value)
  }
  else {
    setValue(value + 1)
    tick(value);
  }
}

const repeatToggleButton = () => {
  const select = document.getElementById('buttonRepeat');

  if (select.className === 'footer-container_right-repeat'){
    select.className = "footer-container_right-repeat-clicked";
  }
  else{
    select.className = "footer-container_right-repeat";
    nextStep();
  }

}


let [playPauseText, setPlayPauseText] = useState("Pause");

const playPause = () => {

  const select = document.getElementById('buttonPlay');

  if (select.className === 'footer-container_center-play'){
    select.className = "footer-container_center-play-clicked"; 
    setPlayPauseText("Play")
    pauseStep();

  }
  else{
    select.className = "footer-container_center-play";
    setPlayPauseText("Pause")
    playStep();

  }

}
///////////////

//dropdown code start
const [showDropDown, setShowDropDown] = useState(false);
let [adjustSpeedText, setAdjustSpeedText] = useState("Adj. Speed");

const adjustSpeedDropdown = () => {
  setShowDropDown(!showDropDown);
}

function changeTextOnButton(label) {
  setShowDropDown(!showDropDown);
  setAdjustSpeedText(label);
  console.log(label);
}

/////////////////


  return (
    <div className='footer-container'>
      <div className='footer-container_left'>
        <button id='buttonPreviousStep' className='footer-container_left-previous' onClick={() => previousStep()}>Back</button>
        <button id='buttonNextStep' className='footer-container_left-next' onClick={() => nextStep()}>Next Step</button>
        <button id='buttonPlayAll' className='footer-container_left-playAll' onClick={() => playAll()}>Play All</button>
      </div>
      <div className='footer-container_center'>
        {/* <button id='buttonPreviousTick' className='footer-container_center-previous' onClick={() => tickBackward()}>Prev Tick</button> */}
        <button id='buttonPlay' className='footer-container_center-play' onClick={() => playPause()}>{playPauseText}</button>
        {/* <button id='buttonNextTick' className='footer-container_center-next' onClick={() => tickForward()}>Next Tick</button> */}
      </div>  
      <div className='footer-container_right'>
        {/* <div id = 'sliderTime' className="footer-container_right-slidecontainer">
          <input type="range" min="0" max={MAX} onChange={(e) => setValue(parseInt(e.target.value))} onClick={() => sliderValue()} style={sliderChange()} value={value} className="footer-container_right-slider" id="myRange"/>
        </div> */}
        <button id='buttonRepeat' className='footer-container_right-repeat' value='off'  onClick={() => repeatToggleButton()}>Repeat</button>
        <div className='footer-container_right-speed-container'>
          <button id='buttonAdjustSpeed' className='footer-container_right-speed' onClick={() => adjustSpeedDropdown() } style={{ display: showDropDown ? "none" : "inline" }}>{adjustSpeedText}</button>
            <div  id='adjustSpeedDropdown' className='footer-container_right-speed-dropdown' style={{ display: showDropDown ? "block" : "none" }}>
              <p onClick={() => changeTextOnButton("2.00x") }>2.00x</p>
              <p onClick={() => changeTextOnButton("1.00x") }>1.00x</p>
              <p onClick={() => changeTextOnButton("0.50x") }>0.50x</p>
              <p onClick={() => changeTextOnButton("0.25x") }>0.25x</p>
            </div>
        </div>
      </div>  
    </div>
  )
}

export default Footer