import React from 'react'
import "./header.css"

import logo from '../../assets/IKEA-Logo.png'
import {changePart, resetCamera, snapView, resetAssembly} from '../modelview/ModelView'

const Header = () => {


  return (
    <div className='header-container'>
      <div className='header-container_left'>
        <img src={logo} alt='logo' />
        <select name="Part" id="partDropdown" onChange={() => changePart()}>
          {/* <option value="select">--SELECT--</option> */}
          <option value="select">--SELECT--</option>
          <option value="LACK-30449908">LACK-30449908</option>
          <option value="DALFRED-60155602">DALFRED-60155602</option>
          <option value="BEKVAM-30178884">BEKVAM-30178884</option>
        </select>
      </div>  
      <div className='header-container_center'>

      </div>
      <div className='header-container_right'>
        <button id='buttonResetView' onClick={() => resetCamera()}>Reset View</button>
        {/* <button id='buttonSnapView' onClick={() => snapView()}>Snap View</button> */}
        <button id='buttonResetModel' onClick={() => {if(window.confirm('Reset Model?')){resetAssembly()};}}>Reset Model</button>
      </div>  
    </div>
  )
}

export default Header