import React from "react";

import { ModelView, Header, Body, Footer } from './containers';

import "./App.css";


const App = () => {

  return (

    <div className="App">
      <ModelView />
      <div className="Overlay">
      <Header />
      {/* <Body /> */}
      <Footer />
      </div>
    </div>

  )
}

export default App;
