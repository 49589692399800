import { AnimationMixer, LoopOnce, LoopRepeat } from "three";
import { adjustSpeed } from "../../../containers/modelview/ModelView";

class Animator {

    constructor(data) {
        this.model = data.scene;
        this.mixer = new AnimationMixer(this.model);
        this.clips = data.animations;
        this.actions = []
        this.currentAction = 0;
        this.initAnimations();
    }

    initAnimations() {
        function clipSort( a, b ) {
            if ( a.name < b.name ){
              return -1;
            }
            if ( a.name > b.name ){
              return 1;
            }
            return 0;
          }
          
        this.clips.sort( clipSort );

        this.clips.sort();
        for (const clip of this.clips) {
            const action = this.mixer.clipAction(clip);
            //console.log(clip.name) //this gets the clip name
            action.loop = LoopOnce;
            action.timeScale = 1;
            action.clampWhenFinished = true;
            this.actions.push(action);
        }

    }

    playAction(action = this.currentAction) {
        
        const speedButton = document.getElementById('buttonAdjustSpeed');
        //const slideButton = document.getElementById('sliderTime');
        var adjustSpeed = speedButton.textContent;
        switch(adjustSpeed) {
            case "2.00x":
                this.actions[action].timeScale = 2;
              break;
            case "1.00x":
                this.actions[action].timeScale = 1;
              break;
            case "0.50x":
                this.actions[action].timeScale = 0.5;
              break;
            case "0.25x":
                this.actions[action].timeScale = 0.25;
              break;
            default:
                this.actions[action].timeScale = 1;
          }
        
        //slideButton.setAttribute("max", `${this.actions[action].duration}`)
        
        this.actions[action].play();
    }

    playNext() {
        const repeatToggle = document.getElementById('buttonRepeat');
        if (this.currentAction < this.actions.length && (this.currentAction === 0 || this.actions[this.currentAction - 1].paused)) {
            console.log(this.currentAction);
            this.playAction();
            if (repeatToggle.className === 'footer-container_right-repeat-clicked'){
                this.actions[this.currentAction].loop = LoopRepeat;
                this.playAction();
            }
            else {
                this.actions[this.currentAction].loop = LoopOnce;
                this.currentAction++;
            }
        } 
        
        // else {
        //     this.currentAction = 0;
        //     this.mixer.removeEventListener('finished');
        // }
    }

    playPrev() {
        const repeatToggle = document.getElementById('buttonRepeat');
        if (repeatToggle.className === 'footer-container_right-repeat-clicked'){
            window.alert("Please turn off Repeat before going Back!")
        }
        else{

            if (this.currentAction > 0 && (this.currentAction !== 0 || this.actions[this.currentAction - 1].paused)) {
                this.currentAction--;
                console.log(this.currentAction);
                this.actions[this.currentAction].stop();
                //this.actions[this.currentAction].play();
            }

        }

    }

    playAll() {
        // https://stackoverflow.com/questions/42383681/verify-when-a-clipaction-animation-finished-playing-in-three-js
        this.mixer.addEventListener('finished', (e) => {
            this.playNext();
        })
        this.playNext();
    }

    pauseStep() {

            if (this.currentAction > 0 && (this.currentAction !== 0 || this.actions[this.currentAction - 1].paused)) {
                // var time = this.actions[this.currentAction].time;
                // console.log(time)
                this.currentAction--;
                this.actions[this.currentAction].paused = true;

                // this.actions[this.currentAction].stop();
                // this.actions[this.currentAction].timeScale = 0;
                // this.actions[this.currentAction].time = 0.75;
                // this.actions[this.currentAction].play();
            }

    }

    playStep(){

            // var time = this.actions[this.currentAction].time;
            // console.log(time)

            this.actions[this.currentAction].paused = false;
            // this.actions[this.currentAction].stop();
            // this.actions[this.currentAction].timeScale = 1;
            // this.actions[this.currentAction].time = 0.75;
            // this.actions[this.currentAction].play();
            this.currentAction++;

    }

    

    tick = (delta) => {
        this.mixer.update(delta);
    }

}

export { Animator };