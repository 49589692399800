// https://discoverthreejs.com/book/
import { createCamera } from './components/camera';
import { createScene } from './components/scene';
import { loadFurniture } from './components/furniture/furniture';
import { createLights } from './components/lights';

import { Loop } from './systems/Loop';
import { createControls } from './systems/controls';
import { createRenderer } from './systems/renderer';
import { Resizer } from './systems/Resizer';

let camera;
let renderer;
let scene;
let loop;
let controls;
let furniture;

class World {

    constructor(container) {
        camera = createCamera();
        scene = createScene();
        renderer = createRenderer();
        loop = new Loop(camera, scene, renderer);
        container.append(renderer.domElement);
        controls = createControls(camera, renderer.domElement);

        loop.updateables.push(controls);

        const { light, light2, light3 } = createLights();

        scene.add(light, light2, light3);

        const resizer = new Resizer(container, camera, renderer);

    }

    async init() {
        ({ furniture } = await loadFurniture());

        loop.updateables.push(furniture);

        scene.add(furniture.model);

        this.resetCamera();

    }

    resetCamera() {
        controls.target.set(0,0,0);
        camera.position.set(-2,1,2);
    }

    async changePart(){
        while(scene.children.length > 0){ 
            scene.remove(scene.children[0]); 
        }
        
        const { light, light2, light3 } = createLights();
        scene.add(light, light2, light3);

        ({ furniture } = await loadFurniture());

        loop.updateables.push(furniture);

        scene.add(furniture.model);

        this.resetCamera();

    }

    nextStep(){
        furniture.playNext();
    }
    previousStep(){
        furniture.playPrev();
    }
    playStep(){
        console.log("play step");
    }
    repeatToggle(){
        console.log("repeat toggle");
    }
    adjustSpeed(){
        console.log("adjust speed");
    }
    playAll(){
        furniture.playAll();
    } 
    playStep(){
        furniture.playStep();
    }

    pauseStep(){
        furniture.pauseStep();
    }

    async resetAssembly(){
        
        while(scene.children.length > 0){ 
            scene.remove(scene.children[0]); 
        }
        
        const { light, light2, light3 } = createLights();
        scene.add(light, light2, light3);

        ({ furniture } = await loadFurniture());

        loop.updateables.push(furniture);

        scene.add(furniture.model);

        this.resetCamera();
    }
    // tickForwards(){
    //     console.log("tick forwards");
    // }
    // tickBackwards(){
    //     console.log("tick backwards");
    // }
    tick(value){
        console.log(value);
    }
    snapView(){
        camera.position.set(0,1,0);
    }


    render() {
        renderer.render(scene, camera);
    }

    start() {
        loop.start();
    }

    stop() {
        loop.stop();
    }

}

export { World };