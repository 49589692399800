import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Animator } from './Animator';

async function loadFurniture() {
    const loader = new GLTFLoader();
    let partString;
    let part;
    //const furnitureData = await loader.loadAsync('/src/assets/models/LACK.glb');
    //const furnitureData = await loader.loadAsync('http://localhost:8000/models/LACK2.glb');
    //const furnitureData = await loader.loadAsync('http://localhost:8000/models/Bekvam.glb');
    //const furnitureData = await loader.loadAsync('http://localhost:8000/models/Dalfred.glb');

    //let hostString = "localhost"
    let hostString = "models.godbehere.org"//add your ip here. this one is mine

    const select = document.getElementById('partDropdown');
    part = select.options[select.selectedIndex].text;

    switch(part) {
        case "LACK-30449908":
            partString = 'https://'+hostString+'/models/LACK.glb'
          break;
        case "DALFRED-60155602":
            partString = 'https://'+hostString+'/models/Dalfred.glb'
          break;
        case "BEKVAM-30178884":
            partString = 'https://'+hostString+'/models/Bekvam.glb'
        break;
        default:
            partString = 'https://'+hostString+'/models/SelectModel.gltf'
      }

    const furnitureData = await loader.loadAsync(partString);

    const furniture = new Animator(furnitureData);

    // furniture.tick = () => {
    //     furniture.rotation.y += 0.01;
    // }

    //console.log('Hallo', furnitureData);

    return { furniture };
}

export { loadFurniture };
