import {useEffect} from 'react'
import "./modelview.css"

import { World } from '../../World/World';

//Control functions
//header
let resetCamera;
let snapView;
let resetAssembly;
let changePart;

//footer
let previousStep;
let nextStep;
let tick;
// let tickBackwards;
// let tickForwards;
let playAll;
let playStep;
let pauseStep;
let repeatToggle;
let adjustSpeed
//////////////////////////

async function startModelView(container) {

    const world = new World(container);

    //Control functions
    //header
    changePart = world.changePart;
    resetCamera = world.resetCamera;
    snapView = world.snapView;
    resetAssembly = world.resetAssembly;

    //footer
    previousStep = world.previousStep;
    nextStep = world.nextStep;
    playAll = world.playAll;
    // tickBackwards = world.tickBackwards;
    // tickForwards = world.tickForwards;
    tick = world.tick;
    playStep = world.playStep;
    pauseStep = world.pauseStep;
    repeatToggle = world.repeatToggle;
    adjustSpeed = world.adjustSpeed;
    ///////////////////////////


    await world.init();
  
    world.start();
  
  }




function ModelView() {

  useEffect(() => {
    
        const container = document.querySelector('#viewport');
        startModelView(container).catch((err) => {
          console.error(err);
        });
    });

    
  return (
    <div className='viewport-container' id = 'viewport'></div>
  )
}

export {ModelView, resetCamera, snapView, resetAssembly, previousStep, nextStep, tick, playAll, playStep, repeatToggle, adjustSpeed, changePart, pauseStep}