import { DirectionalLight } from "three";

function createLights() {
    const light = new DirectionalLight('white', 6);
    const light2 = new DirectionalLight('white', 3);
    const light3 = new DirectionalLight('white', 2);

    light.position.set(10,10,10);
    light2.position.set(-10,10,-10);
    light3.position.set(0,-10,0);

    return { light, light2, light3 };
}

export { createLights };